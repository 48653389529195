import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react'

interface ResolutionContextProps {
  mobile: boolean
  tablet: boolean
  desktop: boolean
}

const ResolutionContext = createContext<ResolutionContextProps>({
  mobile: false,
  tablet: false,
  desktop: false
})

export const ResolutionProvider: React.FC<{ children?: ReactNode, profileId?: string }> = ({ children }) => {
  const [mobile, setMobile] = useState<boolean>(window.innerWidth <= 640 || false)
  const [tablet, setTablet] = useState<boolean>(window.innerWidth <= 992 || false)
  const [desktop, setDesktop] = useState<boolean>(window.innerWidth > 992 || false)

  useEffect(() => {
    const updateDimensions = (): void => {
      const isMobile = window.innerWidth <= 640
      const isTablet = window.innerWidth <= 992
      const isDesktop = window.innerWidth > 992
      if (isMobile !== mobile) {
        setMobile(isMobile)
      }
      if (isTablet !== tablet) {
        setTablet(isTablet)
      }
      if (isDesktop !== desktop) {
        setDesktop(isDesktop)
      }
    }

    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [mobile, tablet])

  return (
    <ResolutionContext.Provider value={{
      mobile, tablet, desktop
    }}>
      {children}
    </ResolutionContext.Provider>
  )
}

export const useResolution = (): ResolutionContextProps => {
  const context = useContext(ResolutionContext)
  if (context === undefined) {
    throw new Error('useResolution must be used within a ResolutionContext')
  }
  return context
}
