import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Styled from './nav-item.styled'
import i18n from 'i18next'
import { type UtilWithTranslationProps } from 'util/core/util-with-translation-props'
import ClickAwayListener from '@mui/material/ClickAwayListener'

interface NavItemProps extends UtilWithTranslationProps {
  mobile?: boolean
  title: any
  menu?: any
  link?: string
  external?: boolean
  target?: string
  onClick?: () => void
  open?: boolean
  onClickAway?: () => void
}

const NavItem: React.FC<NavItemProps> = ({ mobile, title, menu, link, external, target, onClick, open, onClickAway }) => {
  const getMenu = (title: string, menu: any, link?: any, external?: boolean, target?: string, isSub?: boolean, state?: any): any => {
    return (
      <ClickAwayListener onClickAway={() => {
        if (onClickAway) {
          onClickAway()
        }
      }}>
      <Styled.Wrap key={title}
        $mobile={!!mobile}
        $isSub={isSub}
        $isOpen={open}
        className={!isSub && open ? 'hover' : ''}
        onClick={(e) => {
          e.stopPropagation()
          if (!isSub && (onClick != null)) {
            onClick()
          }
          !e.currentTarget.classList.contains('hover')
            ? e.currentTarget.classList.add('hover')
            : e.currentTarget.classList.remove('hover')
        }}
      >
        <Styled.Title $menu={menu} $isSub={isSub} className='title'>
          <Styled.TitleLink
            href={link ? `${external ?? i18n.language === 'uk' ? '' : `/${i18n.language}`}${link}` : undefined}
            title={title}
            target={target === undefined ? '_blank' : ''}
            rel="noopener noreferrer nofollow"
            $isSub={isSub}
          >{title}</Styled.TitleLink>
        </Styled.Title>
        {menu &&
          <Styled.Menu $isSub={isSub} className='menu'>
            {menu.map((item: any, index: number) =>
              <li key={`${index}${item.title}`}>
                {item.sub?.length
                  ? getMenu(item.title, item.sub, item.link, item.external, item.target, true, item.state)
                  : item.divider
                    ? <Styled.Divider />
                    : item.langToggler
                      ? <Styled.LangItem>
                        {item.langToggler}
                      </Styled.LangItem>
                      : item.link && !item.external
                        ? <Styled.InnerLink
                            onClick={item.onClick || undefined}
                            title={typeof item.title === 'string' ? item.title : undefined}
                            to={item.link}
                            target={item.target}
                            state={item.state}
                          >{item.title}</Styled.InnerLink>
                        : <Styled.ExternalLink
                            onClick={item.onClick || undefined}
                            title={typeof item.title === 'string' ? item.title : undefined}
                            href={item.link ? `${item.external || i18n.language === 'uk' ? '' : `/${i18n.language}`}${item.link}` : undefined}
                            target={item.target}
                            rel="noopener noreferrer nofollow"
                          >{item.title}</Styled.ExternalLink>
                }
              </li>
            )}
          </Styled.Menu>
        }
      </Styled.Wrap>
      </ClickAwayListener>
    )
  }

  return getMenu(title, menu, link, external, target)
}

export default withTranslation('dzo')(NavItem)
