import React from 'react'
import * as Styled from './language-toggler.styled'
import i18n from 'i18next'
import { appConfig } from 'util/core/config/app-config.instance'

interface LanguageTogglerProps {
  onChangeLang: (lang: string) => void
}

const LanguageToggler: React.FC<LanguageTogglerProps> = ({ onChangeLang }) =>
  <Styled.LangWrap>
    {Object.keys(appConfig.get('languages')).map((lang: string) =>
      <Styled.Lang
        key={lang}
        $active={i18n.language === lang}
        onClick={() => { onChangeLang(lang) }}
      >
        {appConfig.get('languages')[lang].label}
      </Styled.Lang>
    ).reduce((prev: JSX.Element, current: JSX.Element): any =>
      [prev, (<span key={prev.props}>/</span>), current]
    )}
  </Styled.LangWrap>

export default LanguageToggler
