import { type ProfileInterface } from './types/profile.interface'
import { type CompanyInterface } from './types/company.interface'

import { fetchTransport } from 'util/core/transport/fetch.transport'
import { checkFetchError } from 'util/core/tool/check-fetch-error'
import { appConfig } from 'util/core/config/app-config.instance'

class DataAccessUser {
  public async getProfile (): Promise<ProfileInterface> {
    const response = await fetchTransport.get({
      api: 'USERS_API',
      url: '/api/users/profile'
    })
    checkFetchError(response.status)
    return response.json() as ProfileInterface
  }

  public async getCompany (): Promise<any> {
    const response = await fetchTransport.get({
      api: 'USERS_API',
      url: '/api/users/profile/company'
    })
    checkFetchError(response.status)
    return response.json() as CompanyInterface
  }

  public async getCountries (): Promise<any> {
    const response = await fetch(`${appConfig.get('USERS_API')}/lists/countries.json`)
    checkFetchError(response.status)
    return await response.json()
  }

  public async getСlassificationEconomicEntities (): Promise<any> {
    const response = await fetch(`${appConfig.get('USERS_API')}/lists/classificationEconomicEntities.json`)
    checkFetchError(response.status)
    return await response.json()
  }

  public async getCustomerCategory (): Promise<any> {
    const response = await fetch(`${appConfig.get('USERS_API')}/lists/customerCategory.json`)
    checkFetchError(response.status)
    return await response.json()
  }

  public async forgotPassword (email: string): Promise<any> {
    const response = await fetchTransport.post({
      url: '/api/users/forgot-password',
      api: 'USERS_API',
      data: {
        email
      }
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        response.status.toString()
      )
    }

    const result = {
      ok: response.ok,
      response: await response.json()
    }
    return result
  }

  public async newPassword (recoveryCode: string, password: string): Promise<any> {
    const response = await fetchTransport.post({
      url: '/api/users/new-password',
      api: 'USERS_API',
      data: {
        recoveryCode, password
      }
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error(
        response.status.toString()
      )
    }

    const result = {
      ok: response.ok,
      response: await response.json()
    }
    return result
  }
}

export const dataAccessUser = new DataAccessUser()
