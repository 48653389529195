import { jwtDecode } from 'jwt-decode'
import { appConfig } from '../config/app-config.instance'
import { type AuthInterface } from './auth.type'
import { BehaviorSubject } from 'rxjs'

class AuthService {
  private jwt?: string

  private jwtExpires?: number

  private refreshToken?: string

  private twoFactorAuth_complete?: string

  private readonly twoFactorAuthSubject = new BehaviorSubject<string | null>(null)

  public readonly twoFactorAuthPublisher = this.twoFactorAuthSubject.asObservable()

  public async enableTwoFactorAuth (token: string): Promise<void> {
    if (token != null) {
      this.twoFactorAuth_complete = token
      this.twoFactorAuthSubject.next(this.twoFactorAuth_complete)
    }
  }

  public get isLoggedIn (): boolean {
    return typeof this.jwt !== 'undefined'
  }

  private async refresh (): Promise<void> {
    const response = await fetch(`${appConfig.get('USERS_API')}/auth/token/refresh`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        refresh_token: this.refreshToken
      })
    })

    const json = await response.json()

    if (response.status === 200) {
      await this.processAuth(json)
    } else {
      await this.logout()
      throw new Error(
        response.status.toString()
      )
    }
  }

  private async processAuth (json: AuthInterface): Promise<void> {
    this.jwt = json.token
    this.refreshToken = json.refresh_token
    this.jwtExpires = (jwtDecode(json.token)).exp

    localStorage.jwt = this.jwt
    localStorage.refreshToken = this.refreshToken
    localStorage.jwtExpires = this.jwtExpires

    try {
      await this.loginToOldDzo()
    } catch { }
  }

  public async init (): Promise<void> {
    this.jwt = localStorage.jwt
    this.jwtExpires = localStorage.jwtExpires
    this.refreshToken = localStorage.refreshToken

    if (this.jwt) {
      if (process.env.REACT_APP_ENV !== undefined) {
        const result = await fetch(`${appConfig.get('OLD_DZO')}/checkAuth.php?rand=${Math.random()}`)
        const resultJson = await result.json()
        if (resultJson.isGuest === true) {
          await this.logoutAsync()
        }
      }
    }

    const currentTime = Math.floor((new Date()).getTime() / 1000)
    if (this.jwt && this.jwtExpires && this.jwtExpires < currentTime) {
      try {
        await this.refresh()
      } catch {
        // do nothing
      }
    } else {
      this.loginToOldDzo().then().catch((e) => { console.error(e) })
    }
  }

  private async loginToOldDzo (): Promise<Response | undefined> {
    if (!this.jwt) return

    const formData = new FormData()
    formData.set('jwt', this.jwt)
    // Login to old DZO
    return await fetch(
      appConfig.get('OLD_DZO'),
      {
        method: 'POST',
        body: formData
      }
    )
  }

  public async auth (email: string, password: string, from?: string): Promise<void> {
    const response = await fetch(`${appConfig.get('USERS_API')}/auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password
      })
    })

    const json = await response.json()

    if (response.status === 200) {
      if (json.user['2fa_complete'] === false) {
        void this.enableTwoFactorAuth(json.token)
      } else {
        await this.processAuth(json)
        location.href = from ?? '/'
      }
    } else {
      throw new Error(
        response.status.toString()
      )
    }
  }

  public async twoFactorAuth (otp: string, token: string, from?: string): Promise<void> {
    const response = await fetch(`${appConfig.get('USERS_API')}/api/auth/2fa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        otp
      })
    })

    const json = await response.json()

    if (response.status === 201) {
      await this.processAuth(json)
      location.href = from ?? '/'
    } else {
      throw new Error(
        response.status.toString()
      )
    }
  }

  public async fillAuthHeaders (headers: any): Promise<Response | undefined> {
    if (!this.jwt) return
    const currentTime = Math.floor((new Date()).getTime() / 1000)
    if (this.jwtExpires && this.jwtExpires < currentTime) {
      try {
        await this.refresh()
      } catch (e) {
        console.error(e)
        return
      }
    }
    headers.Authorization = `Bearer ${this.jwt}`
    return headers
  }

  public async logoutAsync (): Promise<void> {
    try {
      await fetch(this.logoutUrl)
      this.clearAuth()
    } catch (error) {
      // do nothing
    }
  }

  public async logout (path?: string): Promise<void> {
    await this.logoutAsync()

    // page refresh or redirect to home page, check proper logic from Ksenia
    if (typeof path !== 'undefined') {
      location.href = `/${path}`
    } else location.reload()
  }

  private get logoutUrl (): string {
    return `${appConfig.get('OLD_DZO')}/cabinet/logout?noRedirect=1&rand=${Math.random()}`
  }

  private clearAuth (): void {
    localStorage.removeItem('jwt')
    localStorage.removeItem('jwtExpires')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('twoFactorAuth_complete')

    this.jwt = undefined
    this.jwtExpires = undefined
    this.refreshToken = undefined
    this.twoFactorAuth_complete = undefined

    document.cookie = 'PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie = 'SWF=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
}

export const authService = new AuthService()
