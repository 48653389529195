import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { appConfig } from './util/core/config/app-config.instance'

const initI18n = async (): Promise<void> => {
  // Parse url for the language. If we got supported one then use it.
  let language = appConfig.get('defaultLanguage')

  const languageUrl = window.location.pathname.substring(0, 3)
  if (languageUrl.length <= 4 && languageUrl.length >= 3 && languageUrl[0] === '/') {
    const possibleLanguage = languageUrl.substring(1, 3)
    if (appConfig.get('languages')[possibleLanguage]) {
      language = possibleLanguage
    }
  }

  await i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      backend: {
        loadPath: `${appConfig.get('TRANSLATIONS_URL')}/{{lng}}/{{ns}}.json`,
        allowMultiLoading: true
      },
      fallbackLng: appConfig.get('defaultLanguage'),
      lng: language,
      debug: false,
      saveMissing: false,
      defaultNS: 'dzo',
      ns: 'dzo',
      fallbackNS: 'dzo',
      keySeparator: false, // we use content as keys
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      }
    })
}

export default initI18n
