import styled from 'styled-components'

export const LangWrap = styled.div`
  display: flex;
  gap: 4px;
  @media screen and (max-width: ${props => props.theme.resolution.lg}) {
    text-align: center;
  }
`
export const Lang = styled.button<{ $active?: boolean }>`
  ${props => props.theme.typography.p1}
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: ${props => props.$active ? props.theme.color.dodgerblue : props.theme.color.black};
`
