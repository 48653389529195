import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ArrowButton } from '../arrow-button/arrow-button'

const StyledArrow = styled(ArrowButton)<{ isHidden?: boolean }>`
  position: sticky;
  margin-right: 40px;
  margin-left: auto;
  margin-bottom: 40px;
  bottom: 64px;
  right: 40px;
  z-index: 2;
  transform: rotate(270deg);
  ${props => props.isHidden ? 'display: none !important;' : ''}
`
export const ScrollTop: React.FC = () => {
  const [hidden, setHidden] = useState(true);

  const checkScroll = () => {
    setHidden(window.scrollY < 77);
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  

  return (
    <StyledArrow
      isHidden={hidden}
      type='black'
      onClick={() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }}
    />
  )
}

