export class AppConfig<T> {
  private config?: T

  public load (config: T): void {
    this.config = config
  }

  public get<K extends keyof T>(field: K): T[K] {
    if (!this.config) {
      throw new Error('Config is not loaded')
    }
    return this.config[field]
  }
}
