import styled from 'styled-components'

export const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  ${(props) => props.theme.typography.p4};
  color: ${(props) => props.theme.color.black};

  @media screen and (max-width: ${(props) => props.theme.resolution.md}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  }
`

export const SocialsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  li {
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.color.lavender};
      border-radius: 8px;
      padding: 12px;

      svg {
        width: 17px;
        height: 17px;
      }

      &:hover {
        background: linear-gradient(
          329.85deg,
          ${(props) => props.theme.color.goldenyellow} 6.75%,
          ${(props) => props.theme.color.dodgerblue} 91.35%
        );

        @media (max-device-width: ${(props) => props.theme.resolution.md}) {
          background: ${(props) => props.theme.color.lavender};
        }

        svg {
          filter: invert(1);

          @media (max-device-width: ${(props) => props.theme.resolution.md}) {
            filter: invert(0);
          }
        }
      }
    }
  }
`
