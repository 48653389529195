import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react'
import { type ProfileInterface } from 'data-access/user/types/profile.interface'
import { dataAccessUser } from 'data-access/user/data-access-user'
import { authService } from './auth.service'
import { Loader } from 'ui/core/loader'

interface UserContextProps {
  user: ProfileInterface | null
}
// Create a context for the user provider
export const UserContext = createContext<UserContextProps>({ user: null })

// User provider component
export const UserProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [user, setUser] = useState<ProfileInterface | null>(null)

  useEffect(() => {
    if (authService.isLoggedIn) {
      void loadUser()
    }
  }, [])

  const loadUser = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const userData = await dataAccessUser.getProfile()
      const parsedUser = {
        ...userData,
        cpvNotificationList: userData.cpvNotificationList.length ? JSON.parse(userData.cpvNotificationList) : ''
      }
      setUser(parsedUser)
    } catch (error: any) {} finally {
      setIsLoading(false)
    }
  }

  return (
    <UserContext.Provider value={{ user }}>
      {isLoading && <Loader />}
      {children}
    </UserContext.Provider>
  )
}

export const useUserProfile = (): UserContextProps => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider')
  }
  return context
}
