import React from 'react'
import * as Styled from './logo.styled'
import { LogoEn, LogoProzoro, LogoUk } from 'ui/core/logos'
import { useTranslation, withTranslation } from 'react-i18next'
import i18n from 'i18next'
import { scrollToSearch } from 'ui/search/helper/scrollToSearch'
import { authService } from 'util/core/service/auth.service'
import { appConfig } from 'util/core/config/app-config.instance'

const logoPaths: Record<string, string> = {
  uk: LogoUk,
  en: LogoEn
}

const Logo: React.FC = () => {
  const { t } = useTranslation()

  const img = <img src={logoPaths[i18n.language]} alt={t('Державні закупівлі онлайн')}/>

  return (
    <Styled.Wrap>
      {authService.isLoggedIn
        ? <Styled.ExternalLink href={`${appConfig.get('OLD_DZO')}`}>{img}</Styled.ExternalLink>
        : <Styled.StyledLink to={'/?scroll-search'} onClick={scrollToSearch}>{img}</Styled.StyledLink>
      }
      <Styled.Logo>
        <img src={LogoProzoro} alt="Prozorro"/>
      </Styled.Logo>
    </Styled.Wrap>
  )
}

export default withTranslation('dzo')(Logo)
