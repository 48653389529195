export const theme = {
  color: {
    black: '#000000',
    blackrussian: '#171725',
    dodgerblue: '#027EFC',
    white: '#FFFFFF',
    linkwater: '#CED6E0',
    dimgrey: '#666666',
    radicalred: '#FF3C4C',
    goldenyellow: '#FFEC00',
    freespeechgreen: '#11D000',
    neoncarrot: '#FD9C3C',
    lightslateblue: '#7979FD',
    whitesmoke: '#F6F6F6',
    lavender: '#EDEDF4',
    quartz: '#E2E2EA',
    shuttlegrey: '#57606F',
    sunshade: '#FFA047',
    solitude: '#B5B5BE',
    boxshadow: '#231F4314'
  },
  typography: {
    h1: `
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      font-family: e-Ukraine;
    `,
    h2: `
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      font-family: e-Ukraine;
    `,
    h3: `
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      font-family: e-Ukraine;
    `,
    h4: `
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      font-family: e-Ukraine;
    `,
    h5: `
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      font-family: e-Ukraine;
    `,
    h6: `
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      font-family: e-Ukraine;
    `,
    h7: `
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-family: e-Ukraine;
    `,
    status: `
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      text-transform: uppercase;
      font-family: e-Ukraine;
    `,
    p1: `
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      font-family: e-Ukraine;
    `,
    p2: `
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      font-family: e-Ukraine;
    `,
    p3: `
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      font-family: e-Ukraine;
    `,
    p4: `
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      font-family: e-Ukraine;
    `,
    p5: `
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      font-family: e-Ukraine;
    `,
    p6: `
      font-size: 10px;
      font-weight: 300;
      line-height: 12px;
      font-family: e-Ukraine;
    `,
    link: `
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      color: #027EFC;
      text-decoration: none;
      cursor: pointer;
      font-family: e-Ukraine;
    `
  },
  resolution: {
    apple: '1440px',
    xxxl: '1360px',
    xxl: '1200px',
    xl: '1024px',
    lg: '992px',
    md: '768px',
    sm: '640px',
    xs: '320px'
  },
  mediaQuery: {
    homepage: '(max-width:  ${props => props.theme.resolution.sm}) and (max-height: 992px), (max-width: 992px) and (max-height: 640px)'
  }
}
