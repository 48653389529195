export const scrollToSearch = (): void => {
  document.getElementById('search')?.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })

  if (window.location.search) {
    const newUrl = window.location.origin + window.location.pathname
    window.history.replaceState({}, document.title, newUrl)
  }
}
