import React, { useState } from 'react'
import * as Styled from './header.styled'
import { useTranslation, withTranslation } from 'react-i18next'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useLocation } from 'react-router-dom'

import { authService } from 'util/core/service/auth.service'

import Logo from './logo/logo'
import { BurgerIcon, CloseIcon, UserIcon } from '../icons'
import NavItem from './nav-item/nav-item'

interface UiHeaderProps {
  mobile: boolean
  menu: any
  children: React.ReactNode
}

const UiHeader: React.FC<UiHeaderProps> = ({ mobile, menu, children }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Styled.Wrap>
      <Styled.Content open={open}>
        <Logo />
        <ClickAwayListener onClickAway={() => { setOpen(false) }}>
          <Styled.Nav>
            {!mobile && children}
            {!authService.isLoggedIn && !mobile
              ? <Styled.StyledLink
                  to='/login'
                  onClick={() => { setOpen(prevOpen => !prevOpen) }}
                  state={{ from: pathname }}
                >
                  <UserIcon /> {t('Увійти')}
                </Styled.StyledLink>
              : <NavItem
                title={mobile ? (open ? <CloseIcon/> : <BurgerIcon/>) : <Styled.User><UserIcon/></Styled.User>}
                menu={menu}
                mobile={mobile}
                open={open}
                onClick={() => { setOpen(prevOpen => !prevOpen) }}
              />
            }
          </Styled.Nav>
        </ClickAwayListener>
      </Styled.Content>
    </Styled.Wrap>
  )
}

export default withTranslation('dzo')(UiHeader)
