import { type GetOptions } from './get.options'
import { type PostOptions } from './post.options'
import { type DeleteOptions } from './delete.options'
import { authService } from '../service/auth.service'
import { appConfig } from '../config/app-config.instance'

class FetchTransport {
  public async get (options: GetOptions): Promise<any> {
    const headers: any = (options.headers != null) || {}
    const api = options.api || 'SEARCH_API'

    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json'
    }

    await authService.fillAuthHeaders(headers)

    const queryString = (options.searchParams != null) ? options.searchParams.toString() : ''

    return await fetch(
      appConfig.get(api).concat(
        options.url,
        (queryString.length > 0) ? '?' : '',
        queryString
      ),
      {
        headers
      }
    )
  }

  public async post (options: PostOptions): Promise<any> {
    const headers: any = (options.headers != null) || {}
    const api = options.api || 'SEARCH_API'

    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/ld+json'
    }

    await authService.fillAuthHeaders(headers)

    return await fetch(
      appConfig.get(api).concat(options.url),
      {
        method: 'POST',
        headers,
        body: JSON.stringify(options.data)
      }
    )
  }

  public async delete (options: DeleteOptions): Promise<any> {
    const headers: any = (options.headers != null) || {}
    const api = options.api || 'SEARCH_API'

    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/ld+json'
    }

    await authService.fillAuthHeaders(headers)

    return await fetch(
      appConfig.get(api).concat(options.url),
      {
        method: 'DELETE',
        headers,
        body: JSON.stringify(options.data)
      }
    )
  }
}

export const fetchTransport = new FetchTransport()
