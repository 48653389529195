import styled from 'styled-components'
import { type HTMLAttributes } from 'react'

interface StyledProps extends HTMLAttributes<HTMLDivElement> {
  $disabled?: boolean
  $buttonType?: 'white' | 'black'
}

export const Arrow = styled.button<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: ${props => props.$buttonType === 'white' ? props.theme.color.white : props.theme.color.black};
  box-shadow: 6px 4px 24px ${({ theme }) => theme.color.boxshadow};

  & svg path {
    fill: ${props => props.$buttonType === 'white' ? props.theme.color.black : props.theme.color.white};
  }

  &:hover {
    background: ${props => `linear-gradient(329.85deg, ${props.theme.color.goldenyellow} 6.75%, ${props.theme.color.dodgerblue} 91.35%)`};
  }

  &:hover svg path {
    fill: ${props => props.theme.color.white};
  }

  ${props => props.disabled
    ? `
    opacity: 0;
    pointer-events: none;
  `
    : ''};
`
