import React from 'react'
import * as Styled from './arrow-button.styled'
import { ArrowIcon } from '../icons'

interface ArrowButtonProps {
  type: 'white' | 'black'
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  disabled?: boolean
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({ className, style, onClick, disabled, type = 'white' }) =>
  <Styled.Arrow
    style={style}
    className={className}
    disabled={disabled}
    $buttonType={type}
    onClick={() => { onClick?.() }}
  >
    <ArrowIcon />
  </Styled.Arrow>
